<template>
  <v-row justify="center">
    <v-dialog
      v-model="isActive"
      width="35%"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Usuarios sin propuesta</span>
        </v-card-title>
        <v-card-text>
          <div class="row pt-2" style="min-height: 500px;">
            <v-simple-table dense v-if="users.length>0" style="width: 100%">
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    Email
                  </th>
                  <th class="text-left">
                    Nombre usuario
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                  v-for="user in users"
                  :key="user.id"
                >
                  <td>{{ user.username }}</td>
                  <td>{{ user.name + ' ' + user.lastName }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>

import {AWARD_PROPOSALS_URL} from '@/constants/ServicesConstants'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import crudServiceMixin from '@/mixins/crudServiceMixin'

export default {
  name: 'UsersWithoutProposalModal',
  data () {
    return {
      users: [],
      isActive: false
    }
  },
  mixins: [loaderMixin, notificationMixin, crudServiceMixin],
  methods: {
    open () {
      this.isActive = true
      this.executeWithLoader(async () => {
        await this.getAudit()
      })
    },
    async getAudit () {
      const response = await this.get(AWARD_PROPOSALS_URL + '/users-without-proposals')
      this.users = response.data
    }
  }
}
</script>
