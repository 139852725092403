<template>
  <div>
    <v-data-table
      dense
      :headers="headers"
      :items="data"
      :page.sync="page"
      :footer-props="footerProps"
      :options.sync="options"
      :server-items-length="total"
      :loading="loading"
      @page-count="pageCount = $event"
    >
      <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
        <slot :name="slot" v-bind="scope"/>
      </template>
    </v-data-table>
    <div class="text-center">
      <v-pagination
        v-model="page"
        :length="pageCount"
      ></v-pagination>
    </div>
  </div>
</template>
<script>
import crudServiceMixin from '@/mixins/crudServiceMixin'

export default {
  name: 'DataTable',
  props: {
    url: {
      default: '',
      type: String
    },
    params: {
      default: () => {
      },
      type: Object
    },
    headers: {
      default: () => [],
      type: Array
    }
  },
  mixins: [crudServiceMixin],
  data () {
    return {
      page: 1,
      pageCount: 0,
      total: 0,
      data: [],
      loading: true,
      options: {
        itemsPerPage: 20
      },
      footerProps: {
        itemsPerPageOptions: [5, 10, 20, 50, 100, 200]
      }
    }
  },
  watch: {
    options: {
      handler () {
        this.getDataFromApi()
      },
      deep: true
    }
  },
  mounted () {
    this.getDataFromApi()
  },
  methods: {
    getDataFromApi () {
      const { page, itemsPerPage } = this.options
      this.loading = true
      this.get(this.url, {
        params: {
          pageSize: itemsPerPage,
          page: page,
          ...this.params
        }
      })
        .then(response => {
          this.data = response.data.data
          this.total = response.data.total
          this.loading = false
        })
        .catch(() => {
          this.data = []
          this.total = 0
          this.loading = false
        })
    }
  }
}
</script>
