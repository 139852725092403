<template>
  <div>
    <banner
      v-if="isUser()"
      bold-title="Mis"
      type="banner--admin"
      title=" Propuestas"
      :options="{ padding: '30px' }"
      description="Consulte o ingrese sus propuestas en este espacio"
    ></banner>
    <banner
      v-if="isAdmin() || isEvaluator()"
      bold-title="Administrador"
      type="banner--admin"
      title=" de Propuestas"
      :options="{ padding: '30px' }"
      description="Gestione las propuestas de los usuarios en este espacio"
    ></banner>

    <div class="px-7 py-5">
      <proposal-filters
        v-if="isAdmin() || isEvaluator()"
        :params="params"
        @search="search"
        @clean="clean"
      ></proposal-filters>

      <div class="col-12 d-flex justify-end">
        <v-btn
          v-if="isAdmin()"
          color="primary"
          class="text-none elevation-0"
          dark
          @click="exportToExcel"
        >
          Exportar Excel
        </v-btn>
        <v-btn
          v-if="isAdmin()"
          color="primary ml-2"
          class="text-none elevation-0"
          dark
          @click="showUsersWithoutProposals(item)"
        >
          Usuarios sin propuesta
        </v-btn>
<!--        <v-btn-->
<!--          v-if="isUser()"-->
<!--          color="primary"-->
<!--          class="text-none elevation-0"-->
<!--          @click="addProposal"-->
<!--          dark-->
<!--        >-->
<!--          Crear propuesta-->
<!--        </v-btn>-->
      </div>
    </div>
    <div class="px-7">
      <div class="row">
        <div class="col-12">
          <data-table
            ref="awardProposals"
            :url="url"
            :params="params"
            :headers="getHeaders"
          >

            <template v-slot:item.created_at="{ item }">
              {{ formatDate(item.created_at) }}
            </template>

            <template v-slot:item.businessName="{ item }">
              <span class="text-uppercase">{{ item.businessName }}</span>
            </template>

            <template v-slot:item.currentStatus="{ item }">
              <v-chip
                :color="proposalStatus[item.currentStatus].color"
                dark
              >
                {{ proposalStatus[item.currentStatus].description }}
              </v-chip>
            </template>

            <template v-slot:item.newStatus="{ item }">
              <v-select
                v-model="item.currentStatus"
                type="text"
                outlined
                hide-details
                dense
                @change="showChangeStatusModal(item)"
                item-text="description"
                item-value="code"
                :items="statuses"
                :disabled="
                  item.currentStatus === proposalStatus.draft.code
                "
              >
              </v-select>
            </template>

            <template v-slot:item.actions="{ item }">
<!--              <v-tooltip v-if="isUser() && item.currentStatus === proposalStatus.draft.code" bottom>-->
<!--                <template v-slot:activator="{ on, attrs }">-->
<!--                  <v-btn-->
<!--                    class="ma-2 elevation-0"-->
<!--                    v-bind="attrs"-->
<!--                    v-on="on"-->
<!--                    @click="edit(item)"-->
<!--                    small-->
<!--                    color="primary"-->
<!--                  >-->
<!--                    <v-icon center dark small>-->
<!--                      fa fa-edit-->
<!--                    </v-icon>-->
<!--                  </v-btn>-->
<!--                </template>-->
<!--                <span>Editar propuesta</span>-->
<!--              </v-tooltip>-->
<!--              <v-tooltip-->
<!--                v-if="isAdmin() || (isUser() && item.currentStatus === proposalStatus.draft.code)"-->
<!--                bottom-->
<!--              >-->
<!--                <template v-slot:activator="{ on, attrs }">-->
<!--                  <v-btn-->
<!--                    class="ma-2 elevation-0"-->
<!--                    v-bind="attrs"-->
<!--                    v-on="on"-->
<!--                    @click="showDeleteConfirm(item)"-->
<!--                    small-->
<!--                    color="red"-->
<!--                  >-->
<!--                    <v-icon center color="white">-->
<!--                      fa fa-times-->
<!--                    </v-icon>-->
<!--                  </v-btn>-->
<!--                </template>-->
<!--                <span>Eliminar propuesta</span>-->
<!--              </v-tooltip>-->
              <v-tooltip v-if="isAdmin() || isEvaluator()" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ma-2"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    @click="showAudit(item)"
                    small
                    color="primary"
                  >
                    <v-icon
                      center
                      small
                      dark
                    >
                      fa fa-info-circle
                    </v-icon>
                  </v-btn>
                </template>
                <span>Auditoria</span>
              </v-tooltip>
              <v-tooltip bottom v-if="(isAdmin() || isEvaluator()) ||
              (isUser && item.currentStatus !== proposalStatus.draft.code)">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ma-2 elevation-0"
                    v-bind="attrs"
                    v-on="on"
                    @click="pdfGenerate(item)"
                    small
                    color="primary"
                    dark
                  >
                    <v-icon center small>
                      fa fa-file-pdf
                    </v-icon>
                  </v-btn>
                </template>
                <span>ver pdf</span>
              </v-tooltip>
            </template>
          </data-table>

          <confirm-dialog
            :title="'Eliminar propuesta'"
            :description="'¿Estás seguro de que deseas eliminar la propuesta (Acción irreversible)?'"
            @onClose="isConfirmActive = false"
            @onConfirm="remove"
            :is-active="isConfirmActive"
          />

          <confirm-dialog
            :title="'Cambiar estado de la propuesta'"
            :description="'¿Estás seguro que deseas cambiar el estado de la propuesta?'"
            @onClose="cancelChangeStatus"
            @onConfirm="changeStatus"
            :is-active="isChangeStatusActive"
          />

          <audit-info-modal ref="auditInfoModal"/>
          <users-without-proposal-modal ref="UsersWithoutProposalModal"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Banner from "@/components/banner/Banner";
import {
  AWARD_PROPOSALS_URL,
  STATUSES_URL,
  CHANGE_STATUS_URL,
  PDF_URL,
  EXCEL_URL
} from "@/constants/ServicesConstants";
import headers from "@/views/private/award-proposals/data/Headers";
import DataTable from "@/components/data-table/DataTable";
import dateMixin from "@/mixins/dateMixin";
import PROPOSAL_STATUS from "@/constants/ProposalStatusContants";
import ConfirmDialog from "@/components/confirm-dialog/ConfirmDialog";
import loaderMixin from "@/mixins/loaderMixin";
import crudServiceMixin from "@/mixins/crudServiceMixin";
import sessionMixin from '@/mixins/sessionMixin';
import ProposalFilters from './components/ProposalFilters';
import AuditInfoModal from './components/AuditInfoModal';
import UsersWithoutProposalModal from './components/UsersWithoutProposalModal';
import notificationMixin from '@/mixins/notificationMixin';

export default {
  name: "AwardProposals",
  async created () {
    await this.getStatuses();
  },
  computed: {
    getHeaders () {
      if (this.isUser()) {
        return headers.filter(
          (header) =>
            header.value != 'newStatus'
        );
      } else if (this.isEvaluator()) {
        return headers.filter(
          (header) =>
            header.value != 'newStatus'
        );
      }
      return headers;
    }
  },
  data () {
    return {
      changeStatusUrl: CHANGE_STATUS_URL,
      statusesUrl: STATUSES_URL,
      excelUrl: EXCEL_URL,
      item: {},
      userRole: null,
      isConfirmActive: false,
      isChangeStatusActive: false,
      proposalStatus: PROPOSAL_STATUS,
      headers,
      url: AWARD_PROPOSALS_URL,
      params: {
        username: '',
        departmentId: '',
        cityId: '',
        currentStatus: '',
        businessName: ''
      },
      selectedItem: null,
      statuses: [],
    };
  },
  methods: {
    addProposal () {
      this.$router.push({name: "createAwardProposal"});
    },
    edit (item) {
      this.$router.push({name: 'editAwardProposal', params: {id: item.id}});
    },
    async getStatuses () {
      const response = await this.get(this.statusesUrl);
      let statuses = response.data;
      this.statuses = statuses.filter(
        (status) =>
          status.code != 'draft' && status.code != 'sent'
      );
    },
    showDeleteConfirm (item) {
      this.selectedItem = item;
      this.isConfirmActive = true;
    },
    showChangeStatusModal (item) {
      this.selectedItem = item;
      this.isChangeStatusActive = true;
    },
    async remove () {
      this.isConfirmActive = false;
      this.showLoader();
      try {
        await this.delete(this.url, this.selectedItem.id);
        this.$refs.awardProposals.getDataFromApi();
        this.hideLoader();
      } catch (e) {
        this.hideLoader();
      }
    },
    clean () {
      this.params.username = '';
      this.params.departmentId = '';
      this.params.cityId = '';
      this.params.currentStatus = '';
      this.params.businessName = '';
      this.$refs.awardProposals.getDataFromApi();
    },
    async pdfGenerate (item) {
      try {
        this.showLoader()
        const response = await this.get(PDF_URL + '/' + item.id, {responseType: 'blob'})
        const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}))
        window.open(url);
      } catch (error) {
        this.showError();
      } finally {
        this.hideLoader();
      }
    },
    search () {
      this.$refs.awardProposals.getDataFromApi();
    },
    async changeStatus () {
      this.showLoader();
      this.isChangeStatusActive = false;
      try {
        await this.post(
          this.changeStatusUrl,
          this.selectedItem
        );
        this.hideLoader();
      } catch (e) {
        this.hideLoader();
      }
    },
    cancelChangeStatus () {
      this.isChangeStatusActive = false;
      this.$refs.awardProposals.getDataFromApi();
    },
    showAudit (item) {
      this.$refs.auditInfoModal.open(item.id)
    },
    showUsersWithoutProposals (item) {
      this.$refs.UsersWithoutProposalModal.open(item.id)
    },
    async exportToExcel () {
      try {
        this.showLoader()
        const response = await this.get(this.excelUrl,{
          responseType: 'blob',
          params:{
            ...this.params
          }
        });
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'Reporte propuestas Premio FISE a la Innovación.xlsx')
        link.click()
      } catch (error) {
        this.showError()
      } finally {
        this.hideLoader()
      }
    }
  },
  mixins: [dateMixin, loaderMixin, crudServiceMixin, sessionMixin, notificationMixin],
  components: {
    Banner,
    DataTable,
    ConfirmDialog,
    ProposalFilters,
    AuditInfoModal,
    UsersWithoutProposalModal
  },
};
</script>
