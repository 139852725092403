const headers = [
  {
    text: 'Fecha',
    value: 'created_at',
    sortable: false
  },
  {
    text: 'NIT',
    value: 'businessIdentification',
    sortable: false
  },
  {
    text: 'Nombre Innovación',
    value: 'innovation.title',
    sortable: false
  },
  {
    text: 'Empresa',
    value: 'businessName',
    sortable: false
  },
  {
    text: 'Departamento',
    value: 'city.department.name',
    sortable: false
  },
  {
    text: 'Ciudad',
    value: 'city.name',
    sortable: false
  },
  {
    text: 'Estado',
    value: 'currentStatus',
    sortable: false
  },
  {
    text: 'Nuevo Estado',
    value: 'newStatus',
    sortable: false,
    width: '200px'
  },
  {
    text: '',
    value: 'actions',
    sortable: false,
    width: '230px'
  }
]
export default headers
