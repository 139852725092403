<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-3">
        <v-text-field
          type="text"
          v-model="params.username"
          outlined
          dense
          label="Usuario/Email:"
        ></v-text-field>
      </div>
      <div class="col-12 col-md-3">
        <v-select
          v-model="params.departmentId"
          type="text"
          outlined
          dense
          item-text="name"
          item-value="id"
          :items="departments"
          @change="getCities"
          label="Departamento"
        ></v-select>
      </div>
      <div class="col-12 col-md-3">
        <v-select
          v-model="params.cityId"
          type="text"
          outlined
          dense
          item-text="name"
          item-value="id"
          :items="cities"
          label="Ciudad"
        ></v-select>
      </div>
      <div class="col-12 col-md-3">
        <v-select
          v-model="params.currentStatus"
          type="text"
          outlined
          dense
          item-text="description"
          item-value="code"
          :items="statuses"
          label="Estado"
        ></v-select>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-3">
        <v-text-field
          type="text"
          v-model="params.businessName"
          outlined
          dense
          label="Nombre Empresa"
        ></v-text-field>
      </div>
      <div class="col-12 col-md-3">
        <v-btn color="primary" depressed class="text-none" dark @click="search">
          <v-icon color="white" size="12" left>
            fa-search
          </v-icon>
          Buscar
        </v-btn>
        <v-btn color="primary ml-2" depressed class="text-none" dark @click="clean">
          <v-icon color="white" size="12" left>
            fa-broom
          </v-icon>
          Limpiar
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import {
  DEPARTMENTS_URL,
  CITIES_URL,
  STATUSES_URL
} from '@/constants/ServicesConstants';
import crudServiceMixin from '@/mixins/crudServiceMixin';
import loaderMixin from '@/mixins/loaderMixin';
export default {
  name: "ProposalFilters",
  async created() {
    this.showLoader();
    await this.getDepartments();
    await this.getStatuses();
    this.hideLoader();
  },
  props: {
    params: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      departments: [],
      cities: [],
      statuses: [],
    };
  },
  methods: {
    async getDepartments() {
      const response = await this.get(DEPARTMENTS_URL + "/" + 47);
      this.departments = response.data;
    },
    getCities() {
      this.cities = [];
      this.showLoader();
      this.get(CITIES_URL + "/" + this.params.departmentId)
        .then((response) => {
          this.cities = response.data;
          this.hideLoader();
        })
        .catch(() => {
          this.showError();
        });
    },
    async getStatuses() {
      const response = await this.get(STATUSES_URL);
      this.statuses = response.data;
    },
    clean () {
      this.$emit('clean')
    },
    search () {
      this.$emit('search')
    }
  },
  mixins:[crudServiceMixin, loaderMixin]
};
</script>
