<template>
  <v-row justify="center">
    <v-dialog
      v-model="isActive"
      width="35%"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Auditoria</span>
        </v-card-title>
        <v-card-text>
          <div class="row pt-2" style="min-height: 500px;">
            <v-simple-table dense v-if="statuses.length>0" style="width: 100%" >
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    Estado
                  </th>
                  <th class="text-left">
                    Fecha
                  </th>
                  <th class="text-left">
                    Usuario
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                  v-for="status in statuses"
                  :key="status.id"
                >
                  <td>{{ proposalStatuses[status.proposalStatus].description }}</td>
                  <td>{{ formatDate(status.created_at) }}</td>
                  <td>{{ status.user ? status.user.name + ' ' + status.user.lastName : '' }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>

import {AWARD_PROPOSALS_URL} from '@/constants/ServicesConstants'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import crudServiceMixin from '@/mixins/crudServiceMixin'
import moment from 'moment'
import ProposalStatusContants from "@/constants/ProposalStatusContants"

export default {
  name: 'AuditModal',
  data () {
    return {
      proposalStatuses: ProposalStatusContants,
      statuses: [],
      isActive: false
    }
  },
  mixins: [loaderMixin, notificationMixin, crudServiceMixin],
  methods: {
    formatDate (date) {
      return moment(date).format('DD/MM/YYYY hh:mm:ss')
    },
    open (id) {
      this.isActive = true
      this.executeWithLoader(async () => {
        await this.getAudit(id)
      })
    },
    async getAudit (id) {
      const response = await this.get(AWARD_PROPOSALS_URL + '/' + id + '/audit')
      this.statuses = response.data
    }
  }
}
</script>
