export const PROPOSAL_STATUS = {
  draft: {
    code: "draft",
    description: "Borrador",
    color: "#222c33",
  },
  sent: {
    code: "sent",
    description: "Enviada",
    color: "#13E23F",
  },
  accepted: {
    code: "accepted",
    description: "Aceptado",
    color: "#ea810f",
  },
  acceptedWithComments: {
    code: "acceptedWithComments",
    description: "Aceptado con observaciones",
    color: "secondary",
  },
  rejected: {
    code: "rejected",
    description: "No aceptado",
    color: "#b71c1c",
  },
  finalist: {
    code: "finalist",
    description: "Finalista",
    color: "#192C66",
  },
  nominated: {
    code: "nominated",
    description: "Nominado",
    color: "#FFC200",
  },
  winner: {
    code: "winner",
    description: "Ganador",
    color: "#138036",
  },
  removed: {
    code: "removed",
    description: "Eliminado",
    color: "#b71c1c",
  },
};
export default PROPOSAL_STATUS;
